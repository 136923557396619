@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap);
#page-landing{
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--color-text-in-primary);
    background: var(--color-primary);
}

.logo-container img {
    height: 10rem;
}

.hero-image{
    width: 100%;
}

.logo-container{
    text-align: center;
    margin-bottom: 3.2rem;
}

.logo-container h2 {
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 4.6rem;
    margin-top: 0.8rem;
}

.buttons-container {
    display: flex;
    justify-content: center;
    margin: 3.2rem 0;
}

.buttons-container a {
    width: 30rem;
    height: 10.4rem;
    border-radius: 0.8rem;
    font: 700 2.0rem Archivo;
    
    display: flex;
    align-items: center;
    justify-content: center;

    text-decoration: none;
    color: var(--color-button-text);

    transition: background-color 0.2s;
}

.buttons-container a:first-child{
    margin-right: 1.6rem;
}

.buttons-container a img {
    width: 4rem;
}

.buttons-container a.study{
    background: var(--color-primary-lighter);
}

.buttons-container a.give-classes{
    background: var(--color-secundary);
}

.buttons-container a.study:hover{
    background: var(--color-primary-light);
}

.buttons-container a.give-classes:hover{
    background: var(--color-secundary-dark);
}

.buttons-container a img{
    margin-right: 2.4rem;
}

.total-connections{
    font-size: 1.4rem;

    display: flex;
    align-items: center;
    justify-content: center;
}

.total-connections img{
    margin-left: 0.8rem;
}

@media (min-width: 1100px){
    #page-landing-content{
        max-width: 1100px;

        display: grid;
        grid-template-rows: 350px 1fr;
        grid-template-columns: 2fr 1fr 1fr;
        grid-template-areas: 
            "logo hero hero"
            "buttons buttons total"
        ;
    }

    .logo-container{
        grid-area: logo;
        align-self: center;
        text-align: left;
        margin: 0;
    }

    .logo-container h2 {
        text-align: left;
        text-align: initial;
        font-size: 3.6rem;
    }

    .logo-container img {
        height: 100%;
    }

    .hero-image{
        grid-area: hero;
        justify-self: end;
    }

    .buttons-container{
        grid-area: buttons;
        justify-content: flex-start;
    }

    .buttons-container a{
        font-size: 2.4rem;
    }

    .total-connections{
        grid-area: total;
        justify-self: end;
    }
}


.page-header{
    display: flex;
    flex-direction: column;
    background-color: var(--color-primary);
}

.page-header .top-bar-container{
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--color-text-in-primary);
    padding: 1.6rem 0;
}

.page-header .top-bar-container a {
    height: 3.2rem;
    transition: opacity 0.2s;
}

.page-header .top-bar-container a:hover{
    opacity: 0.6;
}

.page-header .top-bar-container > img{
    height: 1.6rem;
}

.page-header .header-content{
    width: 90%;
    margin: 0 auto;
    position: relative;
    margin: 3.2rem auto;
}

.page-header .header-content strong {
    font: 700 3.6rem Archivo;
    line-height: 4.2rem;
    color: var(--color-title-in-primary);
}

.page-header .header-content p {
    max-width: 30rem;
    font-size: 1.6rem;
    line-height: 2.6rem;
    color: var(--color-text-in-primary);
    margin-top: 2.4rem;
}

@media (min-width: 700px){
    .page-header{
        height: 340px;
    }

    .page-header .top-bar-container{
        max-width: 1100px;
    }

    .page-header .header-content{
        flex: 1 1;
        max-width: 740px;
        margin: 0 auto;
        padding-bottom: 48px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .page-header .header-content strong{
        max-width: 350px;
        
    }
}
#page-teacher-list{
    width: 100vw;
    height: 100vh;
}

#search-teachers {
    margin-top: 3.2rem;
}

#search-teachers label {
    color: var(--color-text-in-primary);
}

#search-teachers button {
    width: 100%;
    height: 5.6rem;
    background: var(--color-secundary);
    color: var(--color-button-text);
    border: 0;
    border-radius: 0.8rem;
    cursor: pointer;
    font: 70 1.6rem Archivo;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: background-color 0.2s;
    margin-top: 3.2rem;
}

#search-teachers button:hover {
    background: var(--color-secundary-dark);
}


#page-teacher-list main{
    margin: 3.2rem auto;
    width: 90%;
}

@media (min-width: 700px){
    #page-teacher-list {
        max-width: 100%;
    }

    #search-teachers {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 16px;
        -webkit-column-gap: 16px;
                column-gap: 16px;
        position: absolute;
        bottom: -28px; 
    }

    #search-teachers .input-block + .input-block,
    #search-teachers .select-block + .select-block{
        margin-top: 0;
    }

    #page-teacher-list main{
        padding: 3.2rem 0;
        max-width: 740px;
        margin: 0 auto;
    }

}

body {
  font-family: "Quicksand", sans-serif;
  display: flex;
  justify-content: center;
  padding: 0;
  color: #4d4d4d;
}

#title {
  text-align: center;
}

#employee {
  border-collapse: collapse;
  border: 3px solid #ddd;
}

#employee td,
#employee th {
  border: 1px solid #ddd;
  padding: 12px;
}

#employee tr:hover {
  background-color: #ddd;
}

#employee th {
  padding: 10px;
  text-align: center;
  background-color: #4caf50;
  color: white;
}

.opration {
  text-align: center;
}

.button {
  border: none;
  outline: none;
  font-size: 11px;
  font-family: "Quicksand", sans-serif;
  color: #f44336;
  padding: 3px 10px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f44336;
  background-color: transparent;
}

.button:active {
  border: 1px solid blue;
}

.a {
    border: none;
    outline: none;
    font-size: 11px;
    font-family: "Quicksand", sans-serif;
    color: #36f456;
    padding: 3px 10px;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #429511;
    background-color: transparent;
    text-decoration: none;
  }

  .a:active {
    border: 1px solid blue;
  }
.input-block{
    position: relative;
}

.input-block + .input-block{
    margin-top: 1.4rem;
}

.input-block label{
    font-size: 1.4rem;
}

.input-block input{
    width: 100%;
    height: 5.6rem;
    margin-top: 0.8rem;
    border-radius: 0.8rem;
    background: var(--color-input-background);
    border: 1px solid var(--color-line-in-white);
    outline: 0;
    padding: 0 1.6rem;
    font: 1.6rem Archivo;
}

.input-block:focus-within::after{
    width: calc(100% - 3.2rem);
    height: 2px;
    content: '';
    background: var(--color-primary-light);
    position: absolute;
    left: 1.6rem;
    right: 1.6rem;
    bottom: 0;
}
#page-teacher-form {
    width: 100vw;
    height: 100vh;
}

#page-teacher-form .page-header .header-content {
    margin-bottom: 6.4rem;
}

#page-teacher-form main {
    background: var(--color-box-base);
    width: 100%;
    max-width: 74rem;
    border-radius: 0.8rem;
    margin: -3.2rem auto 3.2rem;
    padding-top: 6.4rem;
    overflow: hidden;
}

#page-teacher-form main fieldset {
    border: 0;
    padding: 0 2.4rem;
}

#page-teacher-form main fieldset + fieldset {
    margin-top: 6.4rem;
}

#page-teacher-form main fieldset legend {
    font: 700 2.4rem Archivo;
    color: var(--color-text-title);
    margin-bottom: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 1.6rem;
    border-bottom: 1px solid var(--color-line-in-white);
}

#page-teacher-form main fieldset legend button {
    background: none;
    border: 0;
    color: var(--color-primary);
    font: 700 1.6rem Archivo;
    cursor: pointer;
    transition: color 0.2s;
}

#page-teacher-form main fieldset legend button:hover{
    color: var(--color-primary-dark);
}

#page-teacher-form main fieldset .input-block + .textarea-block,
#page-teacher-form main fieldset .select-block + .input-block {
    margin-top: 2.4rem;
}

#page-teacher-form main label {
    color: var(--color-text-complement);
}

#page-teacher-form main footer{
    padding: 4rem 2.4rem;
    background: var(--color-box-footer);
    border-top: 1px solid var(--color-line-in-white);
    margin-top: 6.4rem;
}

#page-teacher-form main footer p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: var(--color-text-complement);
}

#page-teacher-form main footer p img {
    margin-right: 2rem;
}

#page-teacher-form main footer button{
    width: 100%;
    height: 5.6rem;
    background: var(--color-secundary);
    color: var(--color-button-text);
    border: 0;
    border-radius: 0.8rem;
    cursor: pointer;
    font: 70 1.6rem Archivo;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: background-color 0.2s;
    margin-top: 3.2rem;
}

#page-teacher-form main footer button:hover {
    background: var(--color-secundary-dark);
}

@media (min-width: 700px){
    #page-teacher-form{
        max-width: 100vw;
    }

    #page-teacher-form .page-header .header-content {
        margin-bottom: 0 6.4rem;
    }
    
    #page-teacher-form main fieldset {
        padding: 0 6.4rem;
    }

    #page-teacher-form main .schedule-item {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        grid-column-gap: 1.6rem;
        -webkit-column-gap: 1.6rem;
                column-gap: 1.6rem;
    }

    #page-teacher-form main .schedule-item .input-block {
        margin-top: 0 !important;
    }

    #page-teacher-form main footer {
        padding: 4.0rem 6.4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    #page-teacher-form main footer p {
        justify-content: space-between;
    }

    #page-teacher-form main footer button {
        width: 20rem;
        margin-top: 0;
    }

}
#page-teacher-form {
    width: 100vw;
    height: 100vh;
}

#page-teacher-form .page-header .header-content {
    margin-bottom: 6.4rem;
}

#page-teacher-form main {
    background: var(--color-box-base);
    width: 100%;
    max-width: 74rem;
    border-radius: 0.8rem;
    margin: -3.2rem auto 3.2rem;
    padding-top: 6.4rem;
    overflow: hidden;
}

#page-teacher-form main fieldset {
    border: 0;
    padding: 0 2.4rem;
}

#page-teacher-form main fieldset + fieldset {
    margin-top: 6.4rem;
}

#page-teacher-form main fieldset legend {
    font: 700 2.4rem Archivo;
    color: var(--color-text-title);
    margin-bottom: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 1.6rem;
    border-bottom: 1px solid var(--color-line-in-white);
}

#page-teacher-form main fieldset legend button {
    background: none;
    border: 0;
    color: var(--color-primary);
    font: 700 1.6rem Archivo;
    cursor: pointer;
    transition: color 0.2s;
}

#page-teacher-form main fieldset legend button:hover{
    color: var(--color-primary-dark);
}

#page-teacher-form main fieldset .input-block + .textarea-block,
#page-teacher-form main fieldset .select-block + .input-block {
    margin-top: 2.4rem;
}

#page-teacher-form main label {
    color: var(--color-text-complement);
}

#page-teacher-form main footer{
    padding: 4rem 2.4rem;
    background: var(--color-box-footer);
    border-top: 1px solid var(--color-line-in-white);
    margin-top: 6.4rem;
}

#page-teacher-form main footer p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: var(--color-text-complement);
}

#page-teacher-form main footer p img {
    margin-right: 2rem;
}

#page-teacher-form main footer button{
    width: 100%;
    height: 5.6rem;
    background: var(--color-secundary);
    color: var(--color-button-text);
    border: 0;
    border-radius: 0.8rem;
    cursor: pointer;
    font: 70 1.6rem Archivo;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: background-color 0.2s;
    margin-top: 3.2rem;
}

#page-teacher-form main footer button:hover {
    background: var(--color-secundary-dark);
}

@media (min-width: 700px){
    #page-teacher-form{
        max-width: 100vw;
    }

    #page-teacher-form .page-header .header-content {
        margin-bottom: 0 6.4rem;
    }
    
    #page-teacher-form main fieldset {
        padding: 0 6.4rem;
    }

    #page-teacher-form main .schedule-item {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        grid-column-gap: 1.6rem;
        -webkit-column-gap: 1.6rem;
                column-gap: 1.6rem;
    }

    #page-teacher-form main .schedule-item .input-block {
        margin-top: 0 !important;
    }

    #page-teacher-form main footer {
        padding: 4.0rem 6.4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    #page-teacher-form main footer p {
        justify-content: space-between;
    }

    #page-teacher-form main footer button {
        width: 20rem;
        margin-top: 0;
    }

}
:root {
    font-size: 60%;

    --color-background: #F0F0F7;
    --color-primary-lighter: #9871F5;
    --color-primary-light: #916BEA;
    --color-primary: #8257E5;
    --color-primary-dark: #774DD6;
    --color-primary-darker: #6842C2;
    --color-secundary: #04D361;
    --color-secundary-dark: #04BF58;
    --color-title-in-primary: #FFFFFF;
    --color-text-in-primary: #D4C2FF;
    --color-text-title: #32264D;
    --color-text-complement: #9C98A6;
    --color-text-base: #6A6180;
    --color-line-in-white: #E6E6F0;
    --color-input-background: #F8F8FC;
    --color-button-text: #FFFFFF;
    --color-box-base: #FFFFFF;
    --color-box-footer: #FAFAFC;
  
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body, #root{
    height: 100vh;
}

body{
    background: #F0F0F7;
    background: var(--color-background);
}

#root{
    display: flex;
    align-items: center;
    justify-content: center;
}

body,
input,
button,
textarea{
    font: 500 1.6rem Poppins;
    color: #6A6180;
    color: var(--color-text-base);
}

.container{
    width: 90vw;
    max-width: 700px;
}

@media (min-width: 700px){
    :root{
        font-size: 62.5%;
    }
}
